import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { isAndroid, isIOS } from 'react-device-detect';
import { APP_GOOGLE_URL, APP_IOS_URL } from './config';
import Loading from './views/Components/Loading';
import App from './App';
import register from './registerServiceWorker';
import reportWebVitals from './reportWebVitals';

Sentry.init({
  dsn: 'https://b6774a05711b45bdb46a848bf93a9598@sentry.kivi.bz.it/23',
  integrations: [new Integrations.BrowserTracing()],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.2,

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});
if (window.location.pathname.indexOf('/app/get') !== -1 && isAndroid) {
  window.location.replace(APP_GOOGLE_URL);
} else if (window.location.pathname.indexOf('/app/get') !== -1 && isIOS) {
  window.location.replace(APP_IOS_URL);
} else {
  ReactDOM.render(
    <React.StrictMode>
      <React.Suspense fallback={<Loading />}>
        <App />
      </React.Suspense>
    </React.StrictMode>,
    document.getElementById('root'),
  );
}

register();
reportWebVitals();
