import {
  ApolloLink,
  concat,
  HttpLink,
  NextLink,
  Operation,
} from '@apollo/client';

import { graphQlURL } from '../config';

const httpLink = new HttpLink({ uri: graphQlURL });

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const authMiddleware = new ApolloLink(authenticator);

async function authenticator(operation: Operation, forward: NextLink) {
  // add the authorization to the headers
  const token = window.localStorage.getItem('token');

  operation.setContext({
    headers: {
      authorization: token ? `Bearer ${token}` : '',
    },
  });

  return forward(operation);
}

export const link = concat(authMiddleware, httpLink);
