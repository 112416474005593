import * as React from 'react';
import { Context } from 'react';
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  NavItem,
  NavLink,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { translate } from '../../../config/Translator';
import { UserContext, UserContextTypeLoggedOut } from '../../../config';
import { autobind } from '../../../config/utils';

type FooterState = {
  dropdownOpen: boolean;
};

class Footer extends React.Component<unknown, FooterState> {
  declare context: React.ContextType<Context<UserContextTypeLoggedOut>>;

  constructor(props: unknown) {
    super(props);

    this.state = {
      dropdownOpen: false,
    };
    autobind(this, ['toggle']);
  }

  toggle(): void {
    const { dropdownOpen } = this.state;
    this.setState({
      dropdownOpen: !dropdownOpen,
    });
  }

  render(): JSX.Element {
    const { dropdownOpen } = this.state;
    const { language, changePageLanguage } = this.context;
    return (
      <footer className="app-footer">
        <span>
          A{' '}
          <a href="https://www.kivi.bz.it" target="_blank" rel="noreferrer">
            Kivi
          </a>{' '}
          Production &copy; 2021
        </span>
        <Dropdown
          defaultValue={language}
          isOpen={dropdownOpen}
          direction="up"
          toggle={this.toggle}
          className="language-selector ml-auto mr-auto">
          <DropdownToggle color="light">
            <i
              className="fa fa-language fa"
              title={translate('language', language)}
            />{' '}
            {translate('language', language)}
          </DropdownToggle>
          <DropdownMenu right>
            <DropdownItem
              value="de"
              onClick={() => {
                changePageLanguage('de');
              }}>
              <img
                className="img-thumb"
                src="/src/img/flags/Germany.png"
                alt="Deutsch"
              />{' '}
              Deutsch
            </DropdownItem>
            <DropdownItem
              value="it"
              onClick={() => {
                changePageLanguage('it');
              }}>
              <img src="/src/img/flags/Italy.png" alt="Italiano" /> Italiano
            </DropdownItem>
            <DropdownItem
              value="it"
              onClick={() => {
                changePageLanguage('en');
              }}>
              <img src="/src/img/flags/United-Kingdom.png" alt="English" />{' '}
              English
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
        <Nav className="ml-auto d-md-down-none" navbar>
          <NavItem className="ml-1">
            <NavLink tag={Link} to="/tos">
              {translate('agb', language)}
            </NavLink>
          </NavItem>
          <NavItem className="ml-1">
            <NavLink tag={Link} to="/privacy">
              {translate('privacyPolicy', language)}
            </NavLink>{' '}
          </NavItem>
          <NavItem className="ml-1">
            <NavLink tag={Link} to="/impressum">
              {translate('impressum', language)}
            </NavLink>{' '}
          </NavItem>
          <NavItem className="ml-1">
            <NavLink tag={Link} to="/sitemap">
              {translate('sitemap', language)}
            </NavLink>
          </NavItem>
        </Nav>
      </footer>
    );
  }
}

Footer.contextType = UserContext;
export default Footer;
