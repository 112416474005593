import { gql } from '@apollo/client';
import {
  BILL_FRAGMENT,
  BILL_SETTINGS_FRAGMENT,
  HOLIDAY_FRAGMENT,
  HOST_PRODUCT_FRAGMENT,
  MEDIA_FRAGMENT,
  MESSAGE_FRAGMENT,
  ORDER_FRAGMENT,
  ORDERED_PRODUCT_FRAGMENT,
  PRICE_LIST_FRAGMENT,
  PRODUCT_FRAGMENT,
  PRODUCT_PRICE_LIST_FRAGMENT,
  TRANSLATION_FRAGMENT,
  USER_DETAILS_FRAGMENT,
  USER_FRAGMENT,
  ZONE_FRAGMENT,
} from './fragments';

/// /////////////////////////////////////////////////
/// / Media
/// /////////////////////////////////////////////////
export const UPLOAD_IMAGE = gql`
  ${MEDIA_FRAGMENT}
  mutation uploadImage(
    $media: Upload!
    $alternative: String!
    $caption: String!
  ) {
    upload(
      file: $media
      info: { caption: $caption, alternativeText: $alternative }
    ) {
      data {
        ...MediaFragment
      }
    }
  }
`;

export const DELETE_MEDIA = gql`
  mutation deleteMedia($id: ID!) {
    deleteUploadFile(id: $id) {
      data {
        id
      }
    }
  }
`;

/// /////////////////////////////////////////////////
/// / Translation
/// /////////////////////////////////////////////////
export const CREATE_TRANSLATION = gql`
  mutation CreateTranslation($translation: String!, $langID: ID!) {
    createTranslation(data: { language: $langID, translation: $translation }) {
      data {
        ...TranslationFragment
      }
    }
  }
  ${TRANSLATION_FRAGMENT}
`;
export const EDIT_TRANSLATION = gql`
  mutation EditTranslation($translation: String!, $translationID: ID!) {
    updateTranslation(data: { translation: $translation }, id: $translationID) {
      data {
        ...TranslationFragment
      }
    }
  }
  ${TRANSLATION_FRAGMENT}
`;
/// /////////////////////////////////////////////////
/// / PRODUCTS
/// /////////////////////////////////////////////////
export const CREATE_PRODUCT = gql`
  mutation CreateProduct(
    $bakeryID: ID!
    $productPrice: Float!
    $productWeight: Float!
    $haveToWeight: Boolean!
    $image: ID!
    $productActive: Boolean!
    $vatID: ID!
    $nameDe: ID!
    $nameIt: ID!
    $nameEn: ID!
    $descDe: ID!
    $descIt: ID!
    $descEn: ID!
    $displayOrder: Int!
    $artikelkodex: String
    $availableDays: JSON
  ) {
    createProduct(
      data: {
        gram_price: $productPrice
        thumb: $image
        approx_weight: $productWeight
        have_to_weight: $haveToWeight
        vat: $vatID
        name: [$nameDe, $nameIt, $nameEn]
        description: [$descDe, $descIt, $descEn]
        active: $productActive
        bakery: $bakeryID
        easyback_kodex: $artikelkodex
        available_days: $availableDays
        display_order: $displayOrder
      }
    ) {
      data {
        ...ProductFragment
      }
    }
  }
  ${PRODUCT_FRAGMENT}
`;

export const EDIT_PRODUCT = gql`
  mutation EditProduct(
    $productID: ID!
    $productPrice: Float!
    $productWeight: Float!
    $haveToWeight: Boolean!
    $image: ID!
    $productActive: Boolean!
    $vatID: ID!
    $availableDays: JSON!
  ) {
    updateProduct(
      data: {
        gram_price: $productPrice
        thumb: $image
        approx_weight: $productWeight
        have_to_weight: $haveToWeight
        vat: $vatID
        active: $productActive
        available_days: $availableDays
      }
      id: $productID
    ) {
      data {
        ...ProductFragment
      }
    }
  }
  ${PRODUCT_FRAGMENT}
`;
export const TOGGLE_PRODUCT_ACTIVE = gql`
  mutation ToggleProductActive($productID: ID!, $productActive: Boolean!) {
    updateProduct(data: { active: $productActive }, id: $productID) {
      data {
        ...ProductFragment
      }
    }
  }
  ${PRODUCT_FRAGMENT}
`;

export const DELETE_PRODUCT = gql`
  mutation DeleteProduct($productID: ID!) {
    updateProduct(id: $productID, data: { deleted: true }) {
      data {
        id
      }
    }
  }
`;
export const DELETE_HOST_PRODUCT = gql`
  mutation DeleteHostProduct($productID: ID!) {
    deleteHostProduct(id: $productID) {
      data {
        id
      }
    }
  }
`;

export const CREATE_HOST_PRODUCTS = gql`
  mutation CreateHostProduct(
    $hostID: ID!
    $displayPrice: Float!
    $baseProductID: ID!
  ) {
    createHostProduct(
      data: {
        display_price: $displayPrice
        host: $hostID
        base_product: $baseProductID
      }
    ) {
      data {
        ...HostProductFragment
      }
    }
  }
  ${HOST_PRODUCT_FRAGMENT}
`;
export const EDIT_HOST_PRODUCT = gql`
  mutation EditHostProduct($displayPrice: Float!, $productID: ID!) {
    updateHostProduct(data: { display_price: $displayPrice }, id: $productID) {
      data {
        ...HostProductFragment
      }
    }
  }
  ${HOST_PRODUCT_FRAGMENT}
`;

export const ACCEPT_ALL_PRODUCTS = gql`
  mutation acceptAll($bakeryID: ID!) {
    acceptAll(bakeryID: $bakeryID) {
      data {
        ...HostProductFragment
      }
    }
  }
  ${HOST_PRODUCT_FRAGMENT}
`;

export const CHANGE_PRODUCT_ORDER = gql`
  mutation ChangeProductDisplayOrder(
    $productIDs: [ID]!
    $displayOrders: [Int]!
  ) {
    changeProductDisplayOrder(
      productIDs: $productIDs
      productDisplayOrder: $displayOrders
    ) {
      data {
        id
      }
    }
  }
`;
export const UPLOAD_MEASURED_WEIGHT = gql`
  mutation UploadMeasuredWeight($productID: ID!, $measuredWeight: Float!) {
    updateOrderedProduct(
      id: $productID
      data: { measured_weight: $measuredWeight }
    ) {
      data {
        ...OrderedProductFragment
      }
    }
  }
  ${ORDERED_PRODUCT_FRAGMENT}
`;

/// /////////////////////////////////////////////////
/// / ORDER
/// /////////////////////////////////////////////////
export const PLACE_ORDER = gql`
  mutation PlaceOrder(
    $host: ID!
    $message: String = ""
    $deadline: DateTime!
    $publicID: String!
    $accepted: Boolean!
  ) {
    createOrder(
      data: {
        message: $message
        host: $host
        deadline: $deadline
        public_id: $publicID
        accepted: $accepted
      }
    ) {
      data {
        ...OrderFragment
      }
    }
  }
  ${ORDER_FRAGMENT}
`;

export const CREATE_ORDERED_PRODUCT = gql`
  mutation CreateOrderedProduct(
    $order: ID!
    $product: ID!
    $amount: Float!
    $currentGramPrice: Float!
    $currentDisplayPrice: Float!
    $currentApproxWeight: Float!
    $vat: Float!
    $user: ID!
  ) {
    createOrderedProduct(
      data: {
        order: $order
        base_product: $product
        amount: $amount
        measured_weight: -1
        current_gram_price: $currentGramPrice
        current_vat: $vat
        current_display_price: $currentDisplayPrice
        current_approx_weight: $currentApproxWeight
        missing: 0
        ordered_by: $user
      }
    ) {
      data {
        ...OrderedProductFragment
      }
    }
  }

  ${ORDERED_PRODUCT_FRAGMENT}
`;
export const REMOVE_ORDERED_PRODUCT = gql`
  mutation RemoveOrderedProduct($productID: ID!) {
    deleteOrderedProduct(id: $productID) {
      data {
        id
      }
    }
  }
`;
export const DELETE_ORDER = gql`
  mutation DeleteOrder($orderID: ID!) {
    deleteOrder(id: $orderID) {
      data {
        id
      }
    }
  }
`;

export const ACCEPT_ORDER = gql`
  mutation AcceptOrder($orderID: ID!, $accepted: Boolean!) {
    updateOrder(id: $orderID, data: { accepted: $accepted }) {
      data {
        ...OrderFragment
      }
    }
  }
  ${ORDER_FRAGMENT}
`;

export const CHANGE_DEADLINE = gql`
  ${ORDER_FRAGMENT}
  mutation ChangeDeadline($orderID: ID!, $deadline: DateTime!) {
    updateOrder(id: $orderID, data: { deadline: $deadline }) {
      data {
        ...OrderFragment
      }
    }
  }
`;

/// /////////////////////////////////////////////////
/// / USER
/// /////////////////////////////////////////////////
export const CHANGE_LANG = gql`
  mutation changeLang($userID: ID!, $languageID: ID!) {
    updateUsersPermissionsUser(data: { language: $languageID }, id: $userID) {
      data {
        ...UserFragment
      }
    }
  }
  ${USER_FRAGMENT}
`;

export const LOGIN = gql`
  mutation login($identifier: String!, $password: String!) {
    login(input: { identifier: $identifier, password: $password }) {
      jwt
      user {
        id
      }
    }
  }
`;

export const ACCEPT_PRIVATE = gql`
  mutation AcceptPrivate($userID: ID!) {
    updateUsersPermissionsUser(id: $userID, data: { blocked: false }) {
      data {
        ...UserFragment
      }
    }
  }
  ${USER_FRAGMENT}
`;
export const SET_NO_DELIVERY_COST = gql`
  mutation SetNoDeliveryCost($userID: ID!, $noDeliveryCost: Boolean!) {
    updateUsersPermissionsUser(
      id: $userID
      data: { no_delivery_cost: $noDeliveryCost }
    ) {
      data {
        ...UserFragment
      }
    }
  }
  ${USER_FRAGMENT}
`;
export const DELETE_USER = gql`
  mutation DeleteUser($userID: ID!) {
    updateUsersPermissionsUser(id: $userID, data: { blocked: true }) {
      data {
        ...UserFragment
      }
    }
  }
  ${USER_FRAGMENT}
`;
export const RESTORE_USER = gql`
  mutation RestoreUser($userID: ID!) {
    updateUsersPermissionsUser(id: $userID, data: { blocked: false }) {
      data {
        ...UserFragment
      }
    }
  }
  ${USER_FRAGMENT}
`;

export const EDIT_USER = gql`
  mutation UpdateUser(
    $userID: ID!
    $languageID: ID
    $username: String
    $email: String
    $confirmed: Boolean
    $activeFrom: Date
    $activeTo: Date
    $plan: ID
    $checkOrders: Boolean
    $deliveryZone: ID
    $deliveryZones: [ID]
    $ignoreDeadline: Boolean
    $createBills: Boolean
    $deliversSunday: Boolean
    $deliversSaturday: Boolean
    $takeaway: Boolean
    $deliveryCost: Float
    $deliveryCostMin: Float
  ) {
    updateUsersPermissionsUser(
      data: {
        username: $username
        email: $email
        confirmed: $confirmed
        takeaway: $takeaway
        language: $languageID
        active_from: $activeFrom
        active_to: $activeTo
        plan: $plan
        check_orders: $checkOrders
        delivery_zone: $deliveryZone
        delivery_zones: $deliveryZones
        ignore_deadline: $ignoreDeadline
        delivers_sunday: $deliversSunday
        delivers_saturday: $deliversSaturday
        create_bills: $createBills
        delivery_cost: $deliveryCost
        delivery_cost_min: $deliveryCostMin
      }
      id: $userID
    ) {
      data {
        ...UserFragment
      }
    }
  }
  ${USER_FRAGMENT}
`;

export const INITIAL_USER_UPDATE = gql`
  mutation updateUser(
    $userID: ID!
    $languageID: ID!
    $plan: ID!
    $bakery: Boolean!
    $deliveryZone: ID
  ) {
    updateUsersPermissionsUser(
      data: {
        language: $languageID
        active_from: "2021-01-01"
        active_to: "9999-01-01"
        is_bakery: $bakery
        plan: $plan
        delivery_zone: $deliveryZone
      }
      id: $userID
    ) {
      data {
        ...UserFragment
      }
    }
  }
  ${USER_FRAGMENT}
`;

export const CONFIRM_MAIL = gql`
  ${USER_FRAGMENT}
  mutation confirmMail($confirmation: String!) {
    emailConfirmation(confirmation: $confirmation) {
      jwt
      user {
        ...UserFragment
      }
    }
  }
`;

export const CHANGE_PW = gql`
  mutation updateUser($userID: ID!, $password: String!) {
    updateUsersPermissionsUser(data: { password: $password }, id: $userID) {
      data {
        ...UserFragment
      }
    }
  }
  ${USER_FRAGMENT}
`;

export const CREATE_HOST = gql`
  mutation createHost(
    $languageID: ID!
    $username: String!
    $email: String!
    $password: String!
    $confirmed: Boolean!
    $bakeryID: ID!
    $deliveryZone: ID
  ) {
    createUsersPermissionsUser(
      data: {
        username: $username
        email: $email
        password: $password
        confirmed: $confirmed
        language: $languageID
        blocked: false
        is_host: true
        is_bakery: false
        active_from: "2000-01-01"
        active_to: "9999-03-31"
        bakery: $bakeryID
        delivery_zone: $deliveryZone
        takeaway: false
        role: 1
      }
    ) {
      data {
        ...UserFragment
      }
    }
  }
  ${USER_FRAGMENT}
`;

export const CREATE_GUEST = gql`
  mutation createGuest(
    $languageID: ID!
    $username: String!
    $email: String!
    $password: String!
    $confirmed: Boolean!
    $activeFrom: Date!
    $activeTo: Date!
    $hostID: ID!
  ) {
    createUsersPermissionsUser(
      data: {
        username: $username
        email: $email
        password: $password
        confirmed: $confirmed
        language: $languageID
        blocked: false
        is_host: false
        is_bakery: false
        active_from: $activeFrom
        active_to: $activeTo
        host: $hostID
        takeaway: false
        role: 1
      }
    ) {
      data {
        ...UserFragment
      }
    }
  }
  ${USER_FRAGMENT}
`;

export const REGISTER = gql`
  mutation register($username: String!, $email: String!, $password: String!) {
    register(
      input: { username: $username, email: $email, password: $password }
    ) {
      jwt
      user {
        id
      }
    }
  }
`;
export const ADD_USER_INFO = gql`
  ${USER_DETAILS_FRAGMENT}
  mutation addUserInfo(
    $companyName: String!
    $fullName: String!
    $street: String!
    $houseNumber: String!
    $postalCode: String!
    $city: String!
    $country: String!
    $partitaIva: String!
    $taxNumber: String!
    $recipientCode: String!
    $pec: String!
    $extra: String!
    $deadline: Time!
    $tel: String!
    $userID: ID!
  ) {
    createUserdetail(
      data: {
        company_name: $companyName
        full_name: $fullName
        street: $street
        house_number: $houseNumber
        postal_code: $postalCode
        city: $city
        country: $country
        partita_iva: $partitaIva
        tax_number: $taxNumber
        recipient_code: $recipientCode
        pec: $pec
        extra: $extra
        deadline: $deadline
        tel: $tel
        user: $userID
      }
    ) {
      data {
        ...UserDetailsFragment
      }
    }
  }
`;

export const EDIT_USER_INFO = gql`
  ${USER_DETAILS_FRAGMENT}
  mutation updateUserInfo(
    $companyName: String!
    $fullName: String!
    $street: String!
    $houseNumber: String!
    $postalCode: String!
    $city: String!
    $country: String!
    $partitaIva: String!
    $taxNumber: String!
    $recipientCode: String!
    $pec: String!
    $extra: String!
    $deadline: Time!
    $tel: String!
    $userDetailID: ID!
  ) {
    updateUserdetail(
      data: {
        company_name: $companyName
        full_name: $fullName
        street: $street
        house_number: $houseNumber
        postal_code: $postalCode
        city: $city
        country: $country
        partita_iva: $partitaIva
        tax_number: $taxNumber
        recipient_code: $recipientCode
        pec: $pec
        extra: $extra
        deadline: $deadline
        tel: $tel
      }
      id: $userDetailID
    ) {
      data {
        ...UserDetailsFragment
      }
    }
  }
`;

export const PASSWORD_FORGET = gql`
  mutation forgetPW($email: String!) {
    forgotPassword(email: $email) {
      ok
    }
  }
`;

export const PASSWORD_RESET = gql`
  mutation resetPW(
    $password: String!
    $passwordConfirmation: String!
    $code: String!
  ) {
    resetPassword(
      password: $password
      passwordConfirmation: $passwordConfirmation
      code: $code
    ) {
      jwt
      user {
        id
        username
      }
    }
  }
`;

/// /////////////////////////////////////////////////
/// / ASIDE
/// /////////////////////////////////////////////////

export const DELETE_MESSAGE = gql`
  mutation editMessage($messageID: ID!) {
    deleteMessage(id: $messageID) {
      data {
        id
      }
    }
  }
`;
export const CREATE_MESSAGE = gql`
  mutation writeMessage(
    $writerID: ID!
    $recipients: [ID]!
    $message: String!
    $title: String!
  ) {
    createMessage(
      data: {
        writer: $writerID
        message: $message
        recipients: $recipients
        title: $title
        state: 0
      }
    ) {
      data {
        ...MessageFragment
      }
    }
  }
  ${MESSAGE_FRAGMENT}
`;
export const REPLY_MESSAGE = gql`
  mutation replyMessage(
    $writerID: ID!
    $recipients: [ID]!
    $message: String!
    $replyTo: ID!
  ) {
    createMessage(
      data: {
        message: $message
        recipients: $recipients
        reply_to: $replyTo
        writer: $writerID
      }
    ) {
      data {
        ...MessageFragment
      }
    }
  }
  ${MESSAGE_FRAGMENT}
`;

export const EDIT_MESSAGE = gql`
  mutation editMessage($message: String!, $messageID: ID!) {
    updateMessage(data: { message: $message }, id: $messageID) {
      data {
        ...MessageFragment
      }
    }
  }
  ${MESSAGE_FRAGMENT}
`;

/// /////////////////////////////////////////////////
/// / DELIVERY ZONE
/// /////////////////////////////////////////////////

export const CREATE_DELIVERY_ZONE = gql`
  mutation saveDeliveryZone($name: String!, $createdBy: ID!) {
    createDeliveryZone(data: { name: $name, bakery: $createdBy }) {
      data {
        ...ZoneFragment
      }
    }
  }
  ${ZONE_FRAGMENT}
`;

export const DELETE_DELIVERY_ZONE = gql`
  mutation deleteDeliveryZone($zoneID: ID!) {
    deleteDeliveryZone(id: $zoneID) {
      data {
        id
      }
    }
  }
`;

/// /////////////////////////////////////////////////
/// / HOLIDAY
/// /////////////////////////////////////////////////

export const CREATE_HOLIDAY = gql`
  mutation saveHoliday(
    $name: String!
    $bakeryID: ID!
    $from: Date!
    $to: Date!
  ) {
    createHoliday(
      data: { name: $name, bakery: $bakeryID, from: $from, to: $to }
    ) {
      data {
        ...HolidayFragment
      }
    }
  }
  ${HOLIDAY_FRAGMENT}
`;

export const DELETE_HOLIDAY = gql`
  mutation deleteHoliday($holidayID: ID!) {
    deleteHoliday(id: $holidayID) {
      data {
        id
      }
    }
  }
`;

/// /////////////////////////////////////////////////
/// / Bill
/// /////////////////////////////////////////////////

export const CREATE_BILL = gql`
  mutation createBill(
    $creator: ID!
    $client: ID!
    $billNumber: String!
    $billDate: Date!
    $amount: Float!
    $orders: [ID]!
  ) {
    createBill(
      data: {
        bakery: $creator
        client: $client
        bill_number: $billNumber
        bill_date: $billDate
        amount: $amount
        sent: false
        orders: $orders
        paid: false
      }
    ) {
      data {
        ...BillFragment
      }
    }
  }
  ${BILL_FRAGMENT}
`;

export const EDIT_BILL = gql`
  mutation editBill(
    $billID: ID!
    $billNumber: String!
    $billDate: Date!
    $amount: Float!
    $orders: [ID]!
  ) {
    updateBill(
      data: {
        bill_number: $billNumber
        bill_date: $billDate
        amount: $amount
        orders: $orders
      }
      id: $billID
    ) {
      data {
        ...BillFragment
      }
    }
  }
  ${BILL_FRAGMENT}
`;

export const SEND_BILL = gql`
  mutation sendBill($billID: ID!) {
    updateBill(data: { sent: true }, id: $billID) {
      data {
        ...BillFragment
      }
    }
  }
  ${BILL_FRAGMENT}
`;
export const DELETE_BILL = gql`
  mutation deleteBill($billID: ID!) {
    deleteBill(id: $billID) {
      data {
        id
      }
    }
  }
`;
export const PAY_BILL = gql`
  mutation payBill($billID: ID!) {
    updateBill(data: { paid: true }, id: $billID) {
      data {
        ...BillFragment
      }
    }
  }
  ${BILL_FRAGMENT}
`;

export const GENERATE_BILL_PDF = gql`
  mutation generateBillPdf($billID: ID!) {
    updateBill(data: { paid: true }, id: $billID) {
      data {
        ...BillFragment
      }
    }
  }
  ${BILL_FRAGMENT}
`;

export const CREATE_BILL_SETTINGS = gql`
  ${BILL_SETTINGS_FRAGMENT}
  mutation CreateBillSettings(
    $bakeryID: ID!
    $billNumberFormat: String!
    $ddNumberFormat: String!
    $currentBillNumber: Int!
    $currentDDNumber: Int!
    $pdfLogo: ID!
  ) {
    createBillSetting(
      data: {
        bill_number_format: $billNumberFormat
        dd_number_format: $ddNumberFormat
        current_bill_number: $currentBillNumber
        current_dd_number: $currentDDNumber
        bakery: $bakeryID
        e_bill_data: {}
        pdf_logo: $pdfLogo
        pdf_header: ""
        pdf_footer: ""
      }
    ) {
      data {
        ...BillSettingFragment
      }
    }
  }
`;

export const EDIT_BILL_SETTINGS = gql`
  ${BILL_SETTINGS_FRAGMENT}
  mutation EditBillSettings(
    $billSettingsID: ID!
    $billNumberFormat: String!
    $ddNumberFormat: String!
    $currentBillNumber: Int!
    $currentDDNumber: Int!
    $pdfLogo: ID!
  ) {
    updateBillSetting(
      data: {
        bill_number_format: $billNumberFormat
        dd_number_format: $ddNumberFormat
        current_bill_number: $currentBillNumber
        current_dd_number: $currentDDNumber
        pdf_logo: $pdfLogo
      }
      id: $billSettingsID
    ) {
      data {
        ...BillSettingFragment
      }
    }
  }
`;

export const INCREASE_DD_NUMBER = gql`
  ${BILL_SETTINGS_FRAGMENT}
  mutation IncreaseDDNumber($billSettingsID: ID!, $currentDDNumber: Int!) {
    updateBillSetting(
      data: { current_dd_number: $currentDDNumber }
      id: $billSettingsID
    ) {
      data {
        ...BillSettingFragment
      }
    }
  }
`;

export const INCREASE_BILL_NUMBER = gql`
  ${BILL_SETTINGS_FRAGMENT}
  mutation IncreaseBillNumber($billSettingsID: ID!, $currentBillNumber: Int!) {
    updateBillSetting(
      data: { current_bill_number: $currentBillNumber }
      id: $billSettingsID
    ) {
      data {
        ...BillSettingFragment
      }
    }
  }
`;

/// /////////////////////////////////////////////////
/// / Pricelist
/// /////////////////////////////////////////////////

export const CREATE_PRICE_LIST = gql`
  ${PRICE_LIST_FRAGMENT}
  mutation createPriceList(
    $bakery: ID!
    $name: String!
    $default: Boolean = false
  ) {
    createPriceList(data: { name: $name, bakery: $bakery, default: $default }) {
      data {
        ...PriceListFragment
      }
    }
  }
`;

export const SET_DEFAULT_PRICE_LIST = gql`
  ${PRICE_LIST_FRAGMENT}
  mutation setDefaultPriceList($list: ID!, $default: Boolean = true) {
    updatePriceList(id: $list, data: { default: $default }) {
      data {
        ...PriceListFragment
      }
    }
  }
`;

export const UPDATE_USER_PRICE_LIST = gql`
  ${PRICE_LIST_FRAGMENT}
  mutation updateUserPriceList($userID: ID!, $list: ID!, $add: Boolean!) {
    updateUserPriceList(id: $list, user: $userID, add: $add) {
      data {
        ...PriceListFragment
      }
    }
  }
`;

export const DELETE_PRICE_LIST = gql`
  mutation deletePriceList($list: ID!) {
    deletePriceList(id: $list) {
      data {
        id
      }
    }
  }
`;

export const RENAME_PRICE_LIST = gql`
  ${PRICE_LIST_FRAGMENT}
  mutation renamePriceList($list: ID!, $name: String!) {
    updatePriceList(id: $list, data: { name: $name }) {
      data {
        ...PriceListFragment
      }
    }
  }
`;

export const ADD_PRODUCT_PRICELIST = gql`
  ${PRODUCT_PRICE_LIST_FRAGMENT}
  mutation addProductPriceList($list: ID!, $product: ID!, $price: Float!) {
    createProductPriceList(
      data: { price_list: $list, product: $product, gram_price: $price }
    ) {
      data {
        ...ProductPriceList
      }
    }
  }
`;

export const REMOVE_PRODUCT_PRICELIST = gql`
  mutation removeProductPriceList($listProduct: ID!) {
    deleteProductPriceList(id: $listProduct) {
      data {
        id
      }
    }
  }
`;

export const EDIT_PRODUCT_PRICELIST = gql`
  ${PRICE_LIST_FRAGMENT}
  mutation editProductPriceList($listProduct: ID!, $price: Float!) {
    updateProductPriceList(id: $listProduct, data: { gram_price: $price }) {
      data {
        id
        attributes {
          gram_price
          price_list {
            data {
              ...PriceListFragment
            }
          }
        }
      }
    }
  }
`;
