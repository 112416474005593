import * as React from 'react';
import { NavLink } from 'react-router-dom';
import {
  Badge,
  BadgeProps,
  Button,
  Nav,
  NavItem,
  NavLink as RsNavLink,
} from 'reactstrap';
import classNames from 'classnames';
import {
  navLogout,
  SVDividerItem,
  SVDropdownItem,
  SVLinkItem,
  SVNavItem,
  SVTitleItem,
} from './_nav';
import SidebarMinimizer from '../SidebarMinimizer/SidebarMinimizer';
import { translate } from '../../../config/Translator';
import { UserContext, UserContextTypeLoggedIn } from '../../../config';

class SidebarLoggedOut extends React.Component {
  declare context: React.ContextType<React.Context<UserContextTypeLoggedIn>>;

  handleClick(e: React.MouseEvent<HTMLButtonElement>): void {
    e.preventDefault();
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    e.target.parentElement.classList.toggle('open');
  }

  activeRoute(routeName: string): string {
    return window.location.pathname.indexOf(routeName) > -1
      ? 'nav-item nav-dropdown open'
      : 'nav-item nav-dropdown';
  }

  render(): JSX.Element {
    const { activeRoute } = this;
    const { language } = this.context;

    // badge addon to NavItem
    const badge = (b: BadgeProps | undefined) => {
      if (b) {
        const classes = classNames(b.class);
        return (
          <Badge className={classes} color={b.variant}>
            {b.text}
          </Badge>
        );
      }

      return null;
    };

    // simple wrapper for nav-title item
    const wrapper = (item: SVTitleItem) =>
      item.wrapper && item.wrapper.element
        ? React.createElement(
            item.wrapper.element,
            item.wrapper.attributes,
            translate(item.name, language),
          )
        : translate(item.name, language);

    // nav list section title
    const title = (item: SVTitleItem, key: number) => {
      const classes = classNames('nav-title', item.class);
      return (
        <li key={key} className={classes}>
          {wrapper(item)}{' '}
        </li>
      );
    };

    // nav list divider
    const divider = (item: SVDividerItem, key: number) => {
      const classes = classNames('divider', item.class);
      return <li key={key} className={classes} />;
    };

    // nav item with nav link
    const navItem = (item: SVLinkItem, key: number) => {
      const classes = {
        item: classNames(item.class),
        link: classNames(
          'nav-link',
          item.variant ? `nav-link-${item.variant}` : '',
        ),
        icon: classNames(item.icon),
      };
      return navLink(item, key, classes);
    };

    // nav link
    const navLink = (
      item: SVLinkItem,
      key: number,
      classes: { item: string; link: string; icon: string },
    ) => {
      const url = item.url ? item.url : '';
      return (
        <NavItem key={key} className={classes.item}>
          {isExternal(url) ? (
            <RsNavLink href={url} className={classes.link} active>
              <i className={classes.icon} />
              {translate(item.name, language)}
              {badge(item.badge)}
            </RsNavLink>
          ) : (
            <NavLink to={url} className={classes.link} activeClassName="active">
              <i className={classes.icon} />
              {translate(item.name, language)}
              {badge(item.badge)}
            </NavLink>
          )}
        </NavItem>
      );
    };

    // nav dropdown
    const navDropdown = (item: SVDropdownItem, key: number) => (
      <li key={key} className={activeRoute(item.url)}>
        <Button
          className="nav-link nav-dropdown-toggle"
          onClick={this.handleClick}>
          <i className={item.icon} />
          {translate(item.name, language)}
        </Button>
        <ul className="nav-dropdown-items">{navList(item.children)}</ul>
      </li>
    );

    // nav type
    const navType = (item: SVNavItem, idx: number) =>
      item.title
        ? title(item, idx)
        : item.divider
        ? divider(item, idx)
        : item.children
        ? navDropdown(item, idx)
        : navItem(item, idx);

    // nav list
    const navList = (items: SVNavItem[]) =>
      items.map((item: SVNavItem, index: number) => navType(item, index));

    const isExternal = (url: string) => {
      const link = url ? url.substring(0, 4) : '';
      return link === 'http';
    };

    // sidebar-nav root
    return (
      <div className="sidebar d-lg-none">
        <nav className="sidebar-nav">
          <Nav>{navList(navLogout.items)}</Nav>
        </nav>
        <SidebarMinimizer />
      </div>
    );
  }
}

SidebarLoggedOut.contextType = UserContext;
export default SidebarLoggedOut;
